<template>
  <div class="dbdetail">
    <div class="top">
      <top :selected="selected" :pic="pic"></top>

     
    </div>
   
<div class="infog"></div>
 <div class="info">
   <div class="infoinside">
  <div  class="title0">行业痛点</div>
  
  <div class="point1">☆ 数据展示风格单一</div>
  <div class="point">☆ 产品体验不佳</div>
  <div class="point">☆ 视觉设计和交互能力弱</div>
  <div class="point">☆ 数据反馈不及时</div>
  <div class="point">☆ 数据信息不互通</div>
  <div class="point">☆ 决策管理无数据依据</div>
   <div  class="title2">魔数智屏——数据可视化配置工具</div>
   
  <div class="point">作为数据可视化配置工具，可以让更多的人看到数据可视化的魅力，帮助非专业的工程师通过图形化的界面轻松搭建专业水准的可视化应用，满足用户会议展览、业务监控、风险预警、地理信息分析等多种业务的展示需求。</div>
   <div class="point">魔元术根据“三最”原则——企业最关键、领导最关心、业务最贴近，定制了上百种样式精美的行业数据模板，用户可以从中选择最适合自己的方案模板，简单修改即可使用。以多层级的数据大屏、看板为载体，梳理数据流程、整合企业中的重要指标数据，形成管理的“闭环”，让数据在大屏、在企业流程中“转”起来！</div>
   <div  class="title02">魔数智屏——功能介绍</div>
  
<div  class="smtitle01">1 丰富的数据可视化编辑工具</div>
 <div class="point3">通过丰富的组件库和模板库，以及智能化的辅助手段，极大的降低非专业开发人员的搭建门槛。</div>

<div class="insidePoint" >
<div class="iptitimg"></div>
<div class="smPointTitle">可视化拖拽界面，强大的编辑功能</div>
 


</div>
<div  class="point03">提供多种业务模块级的非图表组件，所见即所得式的配置方式，只需要通过拖拉拽即可创造出专业的可视化应用</div>

<div class="pic01"></div>

<div class="insidePoint" >
<div class="iptitimg"></div>
<div class="smPointTitle">丰富的组件库与模板库</div>
 


</div>
<div  class="point03">多种场景模版，简单修改即可快速投入使用。即便没有设计师，也可搭建专业大屏</div>
<div  class="littletitle">上百种模板库</div>
<div class="pic02"></div>
<div  class="littletitle">丰富的组件库</div>
<div class="pic03"></div>
<div class="insidePoint" >
<div class="iptitimg"></div>
<div class="smPointTitle">支持自定义组件</div>
 


</div>
<div  class="point03">提供开发者工具可以基于一定的组件规范，快速开发出自定义组件满足个性化需求。

自定义组件存放在用户自己的组件库中，可供用户自由复用。</div>
<div class="pic04"></div>

<div  class="smtitle01">2 专业的地理信息可视化</div>
 <div class="point3">通过组件开发工具、编辑器等模块，帮助专业可视化开发人员灵活的进行组件开发和交互配置。</div>
<div class="insidePoint" >
<div class="iptitimg"></div>
<div class="smPointTitle">专业地理信息组件</div>
 


</div>
<div  class="point03">支持绘制地理轨迹、地理飞线、热力分布、3D地球等效果，可以一站式的完成空间地理数据相关的可视分析。</div>
<div class="pic05"></div>

<div class="insidePoint" >
<div class="iptitimg"></div>
<div class="smPointTitle">低代码交互功能开发</div>
 


</div>
<div  class="point03">通过蓝图编辑器来实现组件间的交互配置，复杂的交互逻辑可视化呈现，更易于维护。</div>
<div class="pic06"></div>

<div  class="smtitle001">3 大屏与移动BI互为一体，共同联动控制——移动BI配置工具</div>
 <div class="point3">自助BI报表分析和制作可视化数据大屏的强大工具，通过拖拽图表组件可实现 5 分钟搭建数据可视化页面，组件丰富。通过可视化图表及强大的交互分析能力，为企业快速构建敏捷智能数据分析平台。</div>
<div class="point003">优势</div>
<div  class="smtitle001">1）BI报表与大屏一体化</div>
<div class="point3">集BI报表与大屏功能于一体，满足客户不同场景的可视化需求</div>
<div  class="smtitle001">2）移动端完美适配</div>
<div class="point3">布局自动适配移动端界面，随时随地查看分析可视化数据</div>
<div  class="smtitle001">3）数据产品化  让数据分析思路可沉淀 </div>
<div class="point3">展现、分析、诊断、决策，从数据发现到数据决策，对特定业务数据进行全方位分析</div>
<div  class="smtitle001">4）PC端所有大屏配置功能，移动端可完美复用，双端联控</div>
<div class="point3">PC端所有大屏配置功能，均支持一键生成移动端报表，实现一次配置，多端查看。同时，大屏和移动端联动，可支持移动端远程控制大屏显示内容切换调整功能。</div>


<div class="insidePoint" >
<div class="iptitimg"></div>
<div class="smPointTitle">新一代敏捷BI 让决策不再等数据</div>
</div>
<div  class="point03">丰富的功能和可视化图表模板，快速生成报表，满足企业数据分析和可视化展示需求</div>
<div class="pic001"></div>

<div class="insidePoint" >
<div class="iptitimg"></div>
<div class="smPointTitle">简单好用易上手 快速完成数据分析报告</div>
</div>
<div  class="point03">产品使用门槛低，与数据源打通，拖拽点选、快速响应、即时生效、灵活调整，简单易上手</div>
<div class="pic002"></div>
<div class="insidePoint" >
<div class="iptitimg"></div>
<div class="smPointTitle">极致美学设计、搭配高效的可视化技术</div>
</div>
<div  class="point03">极致的数据美学，结合魔元术可视化技术，生动友好的呈现数据，同时提供丰富的报表模板，快速搭建设计师水准的数据报表</div>
<div class="pic003"></div>
<div class="insidePoint" >
<div class="iptitimg"></div>
<div class="smPointTitle">灵活多端，一次配置多端查看</div>
</div>
<div  class="point03">集成微信或企业微信，手机、PC、大屏等多种设备上自适应展示，随时随地查看分析可视化数据</div>
<div class="pic004"></div>
<div  class="title02">产品特征</div>
<div  class="smtitle0001">1.专业级大数据可视化</div>
<div  class="point03">专精于地理信息与业务数据融合的可视化，提供丰富的行业模版和组件，支持自定义组件</div>
<div  class="smtitle0001">2.实时高效的数据</div>
<div  class="point03">支持从静态数据、CSV 文件、公网数据库，腾讯云数据库和 API 等多种数据源接入数据，通过配置可以动态实时更新</div>
<div  class="smtitle0001">3.拖拽式设计，在线自由布局设计</div>
<div  class="point03">拖拽即可完成样式和数据配置，无需编程就能轻松搭建数据大屏</div>
<div  class="smtitle0001">4.炫酷的3D展现与动画特效</div>
<div  class="point03">具有极强的视觉体验和冲击力，提供丰富3D图表、轮播动画、自动翻页、表格滚动等动画特效，通过简单拖拽方式即可实现3D动画、自动播放等多种特效，科技感十足，让大屏展示效果更具炫酷性。</div>

<div  class="littletitle01">案例1</div>
<div class="pic0003"></div>

<div  class="littletitle01">案例2</div>
<div class="pic0004"></div>
<div  class="littletitle01">案例3</div>
<div class="pic0005"></div>

<div  class="smtitle0001">5.移动端完美适配</div>
<div  class="point03">布局自动适配移动端界面，随时随地查看分析可视化数据</div>
<div  class="smtitle0001">6.灵活部署和推送</div>
<div  class="point03">适配非常规拼接大屏，支持自定义推送，支持本地部署</div>
<div  class="title02">魔数智屏的优势</div>
<div  class="point03">数据可视化的价值在于一眼发现问题，多角度定位问题，分析原因，找到规律，掌握趋势，提升数据洞察力，更要通过数据可视化实现数据分析。</div>
<div  class="smtitle0001">1.更强的图表交互能力</div>
<div  class="point03">颜色预警、高亮联动、图表最大化、图表任意联动等多项功能，提供更好的图表交互能力。</div>
<div  class="smtitle0001">2.更强的探索式分析能力</div>
<div  class="point03">通过报表间智能钻取与多维动态分析，真正实现探索式分析，从问题到原因，通过鼠标即可搞定！</div>
<div  class="smtitle0001">3.丰富精美的行业模板</div>
<div  class="point03">我们定制了几十种样式精美的行业数据模板，用户可以从中选择最适合自己的方案模板，简单修改即可使用。如果已有的模板无法满足你的需要，也可以联系我们做定制开发。</div>
<div  class="smtitle0001">4.强大的地理信息可视化</div>
<div  class="point03">在地理信息可视化方面你可以创建世界地图、全国地图和全国344个区县的地图，可以完成多层级视图(世界级视图、国家视图、省级视图等等)的切换操作，包括视图下钻与返回，还具有海量点图、热力图、动态连线等（此特性为echarts能力）。</div>
<div  class="smtitle0001">5.灵活的适配和部署</div>
<div  class="point03">数据展现方案以网页形式发布，适配chrome、Edge等主流浏览器，还可以使用本地应用程序进行内嵌；移动端可以集成到微信、企业微信、钉钉中；通过定制调整可以支持适配各种尺寸、各种不规则形状的屏幕。</div>
<div  class="title02">行业应用及应用场景</div>
<div  class="smtitle0001">1.实时汇报</div>
<div  class="point03">交互式实时数据可视化大屏，实时监测企业数据，洞悉运营增长，助力智能高效决策。多源适配，可视化建模，配合强大灵活的数据分析能力，轻松实现秒级数据响应，实时发现异常。数据监控，安全预警，异常发现，多种实时场景，灵活适配：</div>
<div  class="point03">（1）多方位、多角度、全景展现企业的各项指标</div>
<div  class="point03">（2）数据实时监控，企业动态一目了然</div>
<div  class="point03">（3）个性化定制，布局灵活，样式丰富</div>
<div class="pic0006"></div>
<div  class="smtitle0001">2.对内监管、激励</div>
<div  class="point03">数据可视化与业务紧密相连，可以让员工轻松的了解到工作的进度，让领导分析其业务表现，同时管理者可以根据此来改变整体业务的方式和方向，帮助分析绩效以及预测未来的绩效。通过分析，能够掌握到企业的动态，做到趋利避害：</div>
<div  class="point03">（1）目标展示，鼓励业务人员完成目标</div>
<div  class="point03">（2）透明激励、横向对比竞争</div>
<div  class="point03">（3）分析复盘，根据实际完成情况，提高管理者决策正确性</div>
<div class="pic0007"></div>
<div  class="smtitle0001">3.对外宣传</div>
<div  class="point03">引入“面向对象”的场景构建方法，对不同的宣传对象在满足保密要求的前提下，实现宣传内容的精准投放，以发挥企业优势、提升客户满意度，有效推动企业品牌建设：</div>
<div  class="point03">（1）直观掌握企业运营状态，及时获取企业数据</div>
<div  class="point03">（2）有效利用数据资产，充分发挥数据价值</div>
<div  class="point03">（3）一体化的数字化展示窗口，及时、高效的进行全域信息的数字化展示</div>
<div class="pic0008"></div>
<div  class="title02">应用行业案例</div>
<div  class="point03">魔数智屏不仅深耕鞋服配等零售行业十六年，具备了完善的数字化解决方案，还能支持跨行业方面的应用，例如化妆品、小百货等，行业匹配性强，具备很强的灵动性和行业匹配度。</div>
<div class="insidePoint01" >
<div class="iptitimg"></div>
<div class="smPointTitle">客户案例一：波司登集团</div>
</div>
<div class="point">波司登集团利用魔数智屏，基于自有的信息化系统，打造出了数据中台监控、供应链监控、产品业态分析、定制业务监控、O2O业务销售监控、车间实时监控、工单处理监控等。</div>
<div class="point">波司登店态数据中台大屏——同一个大屏在不同店态的旗舰店的显示屏上，展示各自的营销动态，辅助店员及店长实时查看货品信息及销售状况，更具直观性及实时性。</div>
<div class="pic0009"></div>
<div class="point">波司登数据中台大屏——主要用于管理层查询集团总体数据情况，支持多维度多角色数据查询，为管理层做决策起到数据支持性作用。</div>
<div class="pic0010"></div>
<div class="point">波司登O2O业务业务监控看板——整体通过数据与地图的集合从不同的展示视角为用户展示不同区域的数据情况，数据动态展示与地图紧密结合，实现了对O2O业务全方位实时监控，O2O业务进度一目了然，帮助业务部门实时跟进，促进用户对企业的决策与效率。</div>
<div class="pic0011"></div>
<div class="insidePoint01" >
<div class="iptitimg"></div>
<div class="smPointTitle">客户案例二：太平鸟集团</div>
</div>
<div class="point">太平鸟2020年起使用魔数智屏产品，在企业形象墙上展示实时销售情况。大屏中对外销售数据经过脱敏处理，在有限的空间中表达庞大的信息量，精炼数据的同时保证数据的完整性，完美契合用户的菱形巨屏。当外部客户参观、领导视察时，菱形大屏的震撼效果彰显了公司的实力。</div>
<div class="pic0006"></div>
<div class="remark">注：所有数据均为模拟数据</div>

<div class="insidePoint0101" >
<div class="iptitimg"></div>
<div class="smPointTitle">客户案例三：安踏集团</div>
</div>
<div class="point">2019年起服务于安踏新零售大数据的可视化系列页面，基于魔元术自有新零售数据中台产品，将安踏的销售数据、会员数据、区域数据等进行监控查看。同时适配PC端操作习惯，提供用户日、周、月、年等条件筛选，完美集成展示大屏和PC看板各自优势，全方位、多层次地展示零售数据，有效提高了对数据管理的宏观掌控、实时监控，为安踏的后续性能、数据分析打好了良好的基础。</div>
<div class="pic0012"></div>
<div class="remark">注：所有数据均为模拟数据</div>

<div class="insidePoint0101" >
<div class="iptitimg"></div>
<div class="smPointTitle">客户案例四：SUNTOP（制造业）</div>
</div>
<div class="point">SUNTOP使用魔数智屏，根据自身硬件设备的传感器和监控，将数据汇总到魔数智屏后端，利用多屏轮播功能，直观的将总览信息、设备硬件信息、客流监控信息等数据整合在一起，还能顾及到数据的实时性，助力制造型企业“数字化转型”，提升管理体验。</div>
<div class="pic0013"></div>
<div class="remark">注：所有数据均为模拟数据</div>
<div  class="title02">持续创新赋能  助力品牌成长</div>

<div  class="titlelast"></div>
<div  class="titlelast02">十六年的技术和服务沉淀  成就知名品牌坚定选择</div>
<div class="pic12"></div>
<div  class="title02">联系方式</div>
<div class="point">电话：4000-620-530</div>
<div class="point">地址：江苏省常熟高新技术产业开发区湖山路2号同济科技广场1幢14层</div>
<div class="point">邮箱：joy.ma@oneerp.com.cn</div>
<div class="pointlast">官网：http://www.oneerp.com.cn</div>
<div class="h20"></div>
</div>



</div>
  <div class="bottomIntroduce">
        <bottomIntroduce @childByValue="childByValue"></bottomIntroduce>
      </div>
  </div>
  
</template>

<script>
import top from "@/components/top.vue";
import bottomIntroduce from "@/components/bottomIntroduce.vue";

export default {
name: "dashboardDetail",
  data() {
    return{
      selected: "product",
      pic: require("../assets/Images/cubg.png"),
      icon: require("../assets/Images/cpbg.png"),
    }
  },
  components:{
    top,
    bottomIntroduce
  },
  methods:{},
  mounted(){

  }
}
</script>
<style lang="scss">
.dbdetail{
  width: 100%;
  height: 100%;
 
}
.info{
width: 100%;

background-color: #fff;

color:#000;

text-align: left;


}
.smPointTitle{
font-size: 0.9rem;
margin-left: 1%;line-height: 0.8rem;
color: #666;
margin-top: 0.2%;

}
.titlebg02{

background-image: url("../assets/Images/titlebg2.png") ;
   background-repeat: no-repeat;
    background-size: 100%;
    width: 30%;
    height: 1.6rem;

}
.titlebg03{
margin-bottom: 2%;
background-image: url("../assets/Images/titlebg2.png") ;
   background-repeat: no-repeat;
    background-size: 100%;
    width: 30%;
    height: 1.6rem;

}
.titlebg04{
  margin-top: 2%;
margin-bottom: 2%;
background-image: url("../assets/Images/titlebg2.png") ;
   background-repeat: no-repeat;
    background-size: 100%;
    width: 30%;
    height: 1.6rem;

}
.point04{
  
margin-left:4%;
color: #fff;
line-height: 1.4rem;
font-size: 0.9rem;
margin-bottom:2%;
}.point07{
  margin-top:2%;
margin-left:4%;
color: #fff;
line-height: 1.4rem;
font-size: 0.9rem;
margin-bottom:2%;
}
.infog{
width: 100%;
height: 5rem;
background-color: #fff;

color:#000;
font-size:0.9rem;


}
.pic001{
background-image: url("../assets/Images/6401.png") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 31rem;
    margin-top: 1%;
   
}
.pic002{
background-image: url("../assets/Images/6402.png") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 31rem;
    margin-top: 1%;
 
}
.pic003{
background-image: url("../assets/Images/6403.png") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 31rem;
    margin-top: 1%;
 
}
.pic0003{
background-image: url("../assets/Images/3D1  GIF.gif") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 31rem;
    margin-top: 1%;
 margin-bottom: 5%;
}
.pic0004{
background-image: url("../assets/Images/640001.gif") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 31rem;
    margin-top: 1%;
  margin-bottom: 5%;
}
.pic0005{
background-image: url("../assets/Images/640002.gif") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 31rem;
    margin-top: 1%;
  margin-bottom: 3%;
}
.pic0006{
background-image: url("../assets/Images/lingxing.png") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 20rem;
    margin-top: 1%;
 }
 .pic0007{
background-image: url("../assets/Images/940.png") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 33rem;
    margin-top: 1%;
 }
  .pic0008{
background-image: url("../assets/Images/1040.png") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 33rem;
    margin-top: 1%;
 }
  .pic0009{
background-image: url("../assets/Images/1140.png") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 36rem;
    margin-top: 1%;
 }
   .pic0010{
background-image: url("../assets/Images/0010.png") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 36rem;
    margin-top: 1%;
 }
   .pic0011{
background-image: url("../assets/Images/0011.png") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 36rem;
    margin-top: 1%;
 }
   .pic0012{
background-image: url("../assets/Images/1210.png") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 33rem;
    margin-top: 1%;
 }
   .pic0013{
background-image: url("../assets/Images/1234.gif") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 28rem;
    margin-top: 1%;
 }
.pic004{
background-image: url("../assets/Images/6405.gif") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 31rem;
    margin-top: 1%;
   
}
.pic01{
background-image: url("../assets/Images/640.gif") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 31rem;
    margin-top: 1%;
    margin-bottom:3%;
}
.pic02{
background-image: url("../assets/Images/640 (1).gif") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 31rem;
    margin-top: 1%;
    margin-bottom: 4%;
}
.pic03{
background-image: url("../assets/Images/640 (2).gif") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 31rem;
    margin-top: 1%;
    margin-bottom: 4%;
}
.pic04{
background-image: url("../assets/Images/640 (3).gif") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 31rem;
    margin-top: 1%;
    margin-bottom: 4%;
}
.pic05{
background-image: url("../assets/Images/640 (4).gif") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 31rem;
    margin-top: 1%;
    margin-bottom: 4%;
}
.pic06{
background-image: url("../assets/Images/640 (5).gif") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 31rem;
    margin-top: 1%;
    margin-bottom: 4%;
}
.infoinside{
width: 70%;
margin-left:15% ;



}
.insidePoint{
display: flex

}
.insidePoint01{
display: flex;
margin-top: 2%;
}
.insidePoint0101{
display: flex;
margin-top: 6%;
}
.iptitimg{
 margin-top: 0.2%;
background-image: url("../assets/Images/smpoint.png") ;
   background-repeat: no-repeat;
    background-size: 100%;
    width: 1%;
    height: 1rem;

}
.remark{
color: #999;
font-size: 0.6rem;
margin-left: 36%;
}

.pic07{
background-image: url("../assets/Images/700.png") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 34rem;
    margin-top: 1%;
}
.pic12{
background-image: url("../assets/Images/840.png") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 23rem;
    margin-top: 1%;
}

.pic08{
background-image: url("../assets/Images/710.png") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 40rem;
    margin-top: 1%;
}
.pic09{
background-image: url("../assets/Images/720.png") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 33rem;
    margin-top: 1%;
}
.pic10{
background-image: url("../assets/Images/730.png") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 33rem;
    margin-top: 1%;
}
.pic11{
background-image: url("../assets/Images/740.png") ;

 background-repeat: no-repeat;
    background-size: 80%;
     width: 100%;
    height: 22rem;
    margin-top: 1%;
}

.infoinside{
width: 70%;
margin-left:15% ;


}
.title0{
 
  background-image: url("../assets/Images/smtitle.png") ;
   background-repeat: repeat;
    background-size: 100%;
  width: 100%;
font-size: 1.1rem;
color: #fff;
line-height: 1.8rem;
border-bottom: solid,0.8rem,#000;
}
.title2{
   margin-top: 2%;
   margin-bottom: 1%;
  background-image: url("../assets/Images/smtitle.png") ;
   background-repeat: repeat;
    background-size: 100%;
  width: 100%;
font-size: 1.1rem;
color: #fff;
line-height: 1.8rem;
border-bottom: solid,0.8rem,#000;

}
.titlelast{
 text-align: center;
  background-image: url("../assets/Images/smtitle.png") ;
   background-repeat: repeat;
    background-size: 100%;
  width: 100%;
font-size: 1.1rem;
color: #fff;
line-height: 2.2rem;
border-bottom: solid,0.8rem,#000;
}
.titlelast02{
 text-align: center;
 

   
  width: 100%;
font-size: 1rem;
color: #2373E3;
line-height: 2rem;
border-bottom: solid,0.8rem,#000;
margin-top: 3%;
margin-bottom: 2%;
font-weight: bold;
}

.h20{width: 100%;height:5rem;background-color: #fff;}
.title02{
  background-image: url("../assets/Images/titlebg.png") ;
   background-repeat: repeat;
    background-size: 100%;
  width: 100%;
font-size: 1.1rem;
color: #fff;
line-height: 2.2rem;
border-bottom: solid,0.8rem,#000;
margin-top: 2%;
}
.smtitle{
line-height: 1.5ren;
color:#2373E3;
margin-top: 1%;
font-size: 1.2rem;
}
.smtitle01{
  
  width: 26%;
line-height: 1.5rem;
color:#2373E3;
margin-bottom: 1%;
margin-top: 1%;
font-size: 1rem;

}
.smtitle0001{
  
  width: 26%;
line-height: 1.1rem;
color:#2373E3;
margin-bottom: 1%;
margin-top: 2%;
font-size: 1rem;
font-weight: bold;
}
.smtitle001{
  
  width: 56%;
line-height: 1.5rem;
color:#2373E3;
margin-bottom: 1%;
margin-top: 1%;
font-size: 1rem;

}
.point{
  width:80%;
  margin-top: 0.5%;
color: #999;
line-height: 1.66rem;
font-size: 0.8rem;

}
.pointlast{
  width:80%;
  margin-top: 0.5%;
color: #999;
line-height: 1.66rem;
font-size: 0.8rem;

}
.point3{
  width:80%;
  margin-bottom: 2%;
  
color: #999;
line-height: 1.1rem;
font-size: 0.8rem;

}

.point1{
  width:80%;
  margin-top: 1%;
color: #999;
line-height: 1.1rem;
font-size: 0.8rem;

}
.point003{
  width:80%;
  margin-bottom: 1%;
  
color: #2373E3;
line-height: 1.3rem;
font-size: 1rem;
font-weight: bold;
}
.point02{
  margin-top: 1%;
color: #666;
line-height: 1.5rem;
font-size: 0.9rem;

}
.point03{
  margin-top: 1%;
color: #999;
line-height: 1.5rem;
font-size: 0.8rem;

}
.point05{
  margin-top: 2%;
color: #2373E3;
line-height: 1.3rem;
font-size: 1rem;
font-weight: bold;
}
.littletitle{
 
color: #2373E3;
line-height: 1rem;
font-size: 0.7rem;
font-weight: bold;
margin-left: 36%;
}
.littletitle01{
 
color: #2373E3;
line-height: 1rem;
font-size: 0.7rem;
font-weight: bold;
margin-left: 39%;
}
.point06{
 
color: #2373E3;
line-height: 1.3rem;
font-size: 1rem;
font-weight: bold;
}
</style>